.hotkeys-modal {
  padding: 8px 0 0;
  overflow: hidden;
  background-color: var(--background-color);
  border-radius: 6px;
  flex-direction: column;

  @media screen and (max-width: 960px) {
    height: 90vh;
  }

  .compose-modal__content {
    background-color: var(--background-color);
    margin: 5px;

    @media screen and (max-width: 960px) {
      flex-direction: column;
      overflow: hidden;
      overflow-y: scroll;
      height: calc(100% - 80px);
      -webkit-overflow-scrolling: touch;
    }
  }

  table {
    thead {
      display: block;
      padding-left: 10px;
      margin-bottom: 10px;
      color: var(--primary-text-color);
      font-size: 16px;
      font-weight: 600;
    }

    tr {
      font-size: 12px;
    }

    td {
      padding: 0 10px 8px;
    }

    kbd {
      display: inline-block;
      padding: 2px 8px;
      background-color: var(--brand-color--med);
      border: 1px solid var(--brand-color--med);
      border-radius: 4px;
    }
  }
}
